import {gql} from "@apollo/client";

export default gql`
  fragment AccordionFragment on AccordionParagraph {
    id
    alignment
    tabs {
      title
      content {
        processed
      }
    }
  }
`;
