import {gql} from "@apollo/client";

export default gql`
  fragment WYSIWYGFragment on RichTextParagraph {
    id
    text {
      format
      processed
    }
    alignment
  }
`;
