import {gql} from "@apollo/client";

export default gql`
  fragment DownloadFragment on DownloadsParagraph {
    id
    alignment
    downloads {
      ...on Document {
        id
        url
        target
        fileName
        fileType
        fileSize
      }
    }
  }
`;
