
export const fonts = {
  zsl: {
    body: 'proxima-nova, sans-serif',
    h1: 'proxima-nova-extra-condensed, sans-serif',
    heading: 'proxima-nova, sans-serif',
    button: 'proxima-nova-condensed, sans-serif',
    feature: 'proxima-nova-extra-condensed, sans-serif',
    eyebrow: 'proxima-nova-condensed, sans-serif',
    infographicStatistic: 'proxima-nova-extra-condensed, sans-serif',
  },
  london: {
    body: 'proxima-soft, sans-serif',
    h1: 'proxima-soft, sans-serif',
    heading: 'proxima-soft, sans-serif',
    button: 'proxima-soft-condensed, sans-serif',
    feature: 'proxima-soft-condensed, sans-serif',
    eyebrow: 'proxima-soft-condensed, sans-serif',
    infographicStatistic: 'proxima-soft-condensed, sans-serif',
  },
  whipsnade: {
    body: 'proxima-soft, sans-serif',
    h1: 'proxima-soft, sans-serif',
    heading: 'proxima-soft, sans-serif',
    button: 'proxima-soft-condensed, sans-serif',
    feature: 'proxima-soft-condensed, sans-serif',
    eyebrow: 'proxima-soft-condensed, sans-serif',
    infographicStatistic: 'proxima-soft-condensed, sans-serif',
  },
};
