import {gql} from "@apollo/client";

export default gql`
  fragment FeatureCardFragment on FeatureCardParagraph {
    id
    featureCardTitle
    cards {
      ... on GeneralCardParagraph {
        title
        path
        target
        teaserImage {
          altText
          url
          sm
          md
          lg
          xl
        }
        teaserDescription
        teaserEyebrow
      }
      ... on TodayAtTheZooCardParagraph {
        title
        startDate
        path
        teaserImage {
          altText
          url
          sm
          md
          lg
          xl
        }
        teaserDescription
        teaserEyebrow
      }
    }
    backgroundColour
    primaryCtaLabel
    secondaryCta {
      url
      title
      target
    }
  }
`;
