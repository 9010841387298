import {gql} from "@apollo/client";

export default gql`
  fragment ImageGalleryFragment on ImageGalleryParagraph {
    id
    gallerySlides {
      ... on GallerySlideParagraph {
        id
        title
        image: gallerySlide {
          altText
          url
          sm
          md
          lg
          xl
        }
        caption: gallerySlideCaption
      }
    }
  }
`;
