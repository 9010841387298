import {gql} from "@apollo/client";

export default gql`
  fragment VideoItemFragment on VideoItemParagraph {
    id
    video {
      id
      url
    }
  }
`;
