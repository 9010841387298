import { GlobalStyle } from '@/theme/globalStyle';
import { GetCMSGlobals, GetCMSGlobals_globals } from '@api/graphql/__generated__/GetCMSGlobals';
import { getCMSGlobals } from "@api/graphql/queries";
import { ApolloProvider } from '@apollo/client';
import { FooterProps } from '@components/organisms/Footer';
import { HeaderProps } from '@components/organisms/Header';
import { initializeApollo } from '@lib/apolloClient';
import { getCookieProDataDomain, getTheme } from "@lib/config";
import { generateCspHeader, generateFeaturePolicyHeader, generatePermissionsPolicyHeader } from "@lib/headers";
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import { JsonLd } from "react-schemaorg";
import { WebSite } from "schema-dts";
import Theme, { themes } from 'theme';

const globalProps = {
  __typename: 'DrupalGlobals',
  metadata: {
    __typename: 'Metadata',
    homepageUrl: '/',
    siteName: '',
  },
  mainNavigationLinks: [],
  mainNavigationSettings: {
    __typename: "DrupalGobalMainNavigationSettings",
    shopUrl: {
      __typename: "LinkFieldWithTitle",
      url: 'https://shop.zsl.org',
      title: 'Shop',
    },
    ctaUrl: {
      __typename: "LinkFieldWithTitle",
      url: 'https://donate.zsl.org',
      title: 'Donate',
    },
    secondaryUrl: {
      __typename: "LinkFieldWithTitle",
      url: 'https://www.zsl.org/support-us/patrons',
      title: 'Patronage',
    },
    colourTheme: 'default',
  },
  footerLinks: [],
  footerSettings: {
    __typename: "DrupalGlobalsFooterSettings",
    social: [],
    content: {
      __typename: "FormattedField",
      processed: '',
    },
    newsletter: {
      __typename: "NewsletterSignUpBlockParagraph",
      id: '',
      title: '',
      supportingText: null,
      callToAction: {
        __typename: "LinkFieldWithTitle",
        title: '',
        url: '',
        target: null,
      },
    },
    copyright: {
      __typename: "FooterCopyright",
      description: {
        __typename: "FormattedField",
        processed: '',
      },
      links: []
    },
  },
  donationPopup: null,
  nodeDetail: null,
  cspNonce: '',
} as GetCMSGlobals_globals;

interface AppCspProps {
  cspNonce: string;
}
interface ZSLPageProps {
  theme?: keyof typeof themes|null;
}
interface ZSLAppProps extends AppProps<ZSLPageProps> {
  pageProps: ZSLPageProps;
}

const Footer = dynamic(() => import('@components/organisms/Footer'), {
  ssr: true,
})

const Header = dynamic(() => import('@components/organisms/Header'), {
  ssr: true,
})
function ZSLApp({ Component, pageProps, globals, cspNonce }: ZSLAppProps & GetCMSGlobals & AppCspProps) {
  const client = initializeApollo();
  // Workaround for local dev.
  const theme = process.env.NODE_ENV === 'production' ? getTheme() : (pageProps?.theme || getTheme());
  // Check for an error condition
  const error = (!globals || globals.hasOwnProperty('error'));
  const footerProps: FooterProps = {
    menu: globals.footerLinks,
    ...globals.footerSettings,
  }
  // If the GTM code changes, the SHA-256 hashes need to be updated in the CSP security header in next.config.js.
  const gtmCode = (theme === 'zsl' ? 'GTM-523FP7M' : 'GTM-JPGR');
  const gaCode = (theme === 'zsl' ? 'G-6XYLLY3DVH' : 'G-MWZNHV9X89');
  const cookieProDataDomain = getCookieProDataDomain(theme);

  const navProps: HeaderProps = {
    ctaDonate: globals.mainNavigationSettings.ctaUrl,
    navigation: {
      open: false,
      searchOpen: false,
      menuItems: globals.mainNavigationLinks,
      ctaOne: globals.mainNavigationSettings.shopUrl,
      ctaTwo: globals.mainNavigationSettings?.secondaryUrl||undefined,
    },
    colourTheme: globals.mainNavigationSettings.colourTheme,
  };

  const enableHeader= () => {
    const disabledHeaderContentTypes = ['campaign', 'donation']
    const nodeBundle = globals?.nodeDetail?.bundle;

    if (nodeBundle) {
      if (disabledHeaderContentTypes.includes(nodeBundle)) {
        return false;
      }
    }
    return true;
  }

  return (
    <>
      {/* <html lang="en"> */}
      <ApolloProvider client={client}>
        <Head>
          <link rel="preload" as="script" href="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js" />
          {theme === 'zsl' ?
            <link rel="stylesheet" href="https://use.typekit.net/zpa6xot.css" /> :
            <link rel="stylesheet" href="https://use.typekit.net/nur3lqw.css" />}
          <JsonLd<WebSite>
            item={{
              "@context": "https://schema.org",
              "@type": "WebSite",
              name: globals.metadata.siteName,
              "url": globals.metadata.homepageUrl,
              "potentialAction": {
                "@type": "SearchAction",
                "target": `${globals.metadata.homepageUrl}/search?keywords={search_term_string}`,
                "query-input": "required name=search_term_string"
              }
            }}
          />
        </Head>
        {/* OptanonConsentNoticeStart */}
        {cookieProDataDomain &&
        <Script id="cookiepro-template"
          nonce={cspNonce}
          async
          strategy="afterInteractive"
          src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
          charSet="UTF-8"
          data-domain-script={cookieProDataDomain}
        />}
        {/* Custom GTM embed script with added `gtmNonce` variable. DO NOT MODIFY. */}
        <Script nonce={cspNonce} async id="google-analytics" strategy="afterInteractive">
          {`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));
w.gtmNonce=n.nonce||n.getAttribute('nonce');
f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmCode}');
gtag('get', '${gaCode}', 'client_id', (client_id) => {
  sessionStorage.setItem('client_id', client_id);
});
gtag('get', '${gaCode}', 'session_id', (session_id) => {
  sessionStorage.setItem('session_id', session_id);
});
          `.trim()}
        </Script>
        {/* End Google Tag Manager */}

        {/* Dot Digital */}
        <Script nonce={cspNonce} async id="dot-digital" strategy="afterInteractive">
          {`
          /* ----------------------------------------------------------------------------------------------------
          IMPORTANT!
          The code below requires a developer to install and setup.
          Please do not simply copy/paste this onto your website.
          ---------------------------------------------------------------------------------------------------- */
          // Initiate Web Behaviour Tracking (this section MUST come prior any other dmPt calls)
          // Do not change this
          (function(w,d,u,t,o,c){w['dmtrackingobjectname']=o;c=d.createElement(t);c.async=1;c.src=u;t=d.getElementsByTagName
          (t)[0];t.parentNode.insertBefore(c,t);w[o]=w[o]||function(){(w[o].q=w[o].q||[]).push(arguments);};
          })(window, document, '//static.trackedweb.net/js/_dmptv4.js', 'script', 'dmPt');
          window.dmPt('create', 'DM-5105397364-01', 'londonzoo.org,whipsnadezoo.org,zsl.org');

          /* ----------------------------------------------------------------------------------------------------
          Tracks page visit whenever dmPt function is called, e.g. on start up, after ajax request etc
          window.dmPt('track');
          Tracks page visit with custom key values (object with name/value pairs, only primitive types!)
          window.dmPt('track', { customVar: 'Hello', anotherKey: 'World' });
          Tracks page visit with custom email address. dmPt('track') should be called first (any time before, but must be done).
          window.dmPt('track');
          ---------------------------------------------------------------------------------------------------- */


          // window.dmPt("identify", "example@example.com");  // Hardcoded example, inject contact email address.
          // window.dmPt("identify", { email: "example@example.com" }); // Hardcoded example, inject email as object.
          // window.dmPt("identify", { email: "example@example.com", mobileNumber: "00447911123456" }); // Hardcoded example, inject email and mobile-number as object.
          `}
        </Script>
        {/* End Dot Digital */}

        <Theme theme={theme}>
          <GlobalStyle />
          {!error && enableHeader() && <Header {...navProps} />}
          <Component
            {...pageProps}
            {...globals.metadata}
            mainNavigationLinks={globals.mainNavigationLinks}
            mainNavigationSettings={globals.mainNavigationSettings}
            donationPopup={globals.donationPopup}
          />
          {!error && <Footer {...footerProps} />}
        </Theme>
        {/* Smooth corner paint worklet */}
        {theme !== 'zsl' && <Script id="smooth-corners" nonce={cspNonce} async data-ot-ignore strategy="afterInteractive" src="/smooth-corners/smooth-corners.js" />}
        <Script nonce={cspNonce} async data-ot-ignore strategy="afterInteractive" id="frame-break" src="/frame-break.js" />
        {/* Google Tag Manager */}
        {theme && <noscript>
          <iframe src={`https://www.googletagmanager.com/ns.html?id=${gtmCode}`}
                  height="0" width="0" style={{display: 'none', visibility: 'hidden'}}>
          </iframe>
        </noscript>
        }
        {/* End Google Tag Manager (noscript) */}
      </ApolloProvider>
    </>
  );
}

ZSLApp.getInitialProps = async (
  appContext: AppContext
): Promise<AppInitialProps & GetCMSGlobals & AppCspProps> => {
  const client = initializeApollo();

  // Get globals
  const globals = await client
    .query<GetCMSGlobals>({
      query: getCMSGlobals,
      variables: {
        path:  appContext.ctx.asPath
      },
    })
    .then((res) => {
      return res.data.globals;
    })
    .catch((e) => {
      return {
        error: e,
        ...globalProps,
      };
    });

  // set cache for use with Cloudflare
  if (process.env.NODE_ENV !== 'development') {
    if (appContext.ctx && appContext.ctx.res) {
      // Browser cache for 15m. Cloudflare cache for 1y. Pages will be invalidated on edit
      appContext.ctx.res.setHeader('Cache-Control', 'public, max-age=3600, s-maxage=31536000, stale-while-revalidate=7200, stale-if-error=7200');
    }
  }

  appContext.ctx.res?.setHeader('Content-Security-Policy', generateCspHeader(globals.cspNonce));
  appContext.ctx.res?.setHeader('Feature-Policy', generateFeaturePolicyHeader());
  appContext.ctx.res?.setHeader('Permissions-Policy', generatePermissionsPolicyHeader());

  // calls page's `getInitialProps` and fills `appProps.pageProps`
  let appProps = await App.getInitialProps(appContext);
  return {
    cspNonce: globals.cspNonce,
    ...appProps,
    globals,
  };
};

export default ZSLApp;
