import { gql } from "@apollo/client";
import { DonateMultipleFragment } from "../Donate/Donate.fragment";

export default gql`
  ${DonateMultipleFragment}
  fragment DonatePopup on DonatePopup  {
    title
    description
    content {
      ...DonateMultipleAmountsParagraph
    }
  }
`
