import {ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject, StoreObject,} from "@apollo/client";
import merge from "deepmerge";
import isEqual from "lodash.isequal";
import {createPersistedQueryLink} from "@apollo/client/link/persisted-queries";
import {sha256} from 'crypto-hash';
import {getGraphqlUri} from "@lib/config";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient: ApolloClient<NormalizedCacheObject>;

function createApolloClient() {
  const uri = getGraphqlUri();
  if (!uri) {
    throw new Error(`Missing Graphql URL`);
  }

  const linkChain = createPersistedQueryLink({ sha256 }).concat(
    new HttpLink({
      uri: uri,
      credentials: "same-origin",
    }));

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: linkChain,
    cache: new InMemoryCache({}),
  });
}

export function initializeApollo(
  initialState: null | { [x: string]: StoreObject | undefined } = null,
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState !== null) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}
