import { gql } from '@apollo/client';

export default gql`
  fragment EventListingFragment on EventListingParagraph {
    id
    title
    cards {
      ... on EventCardParagraph {
        id
        title
        description
        image {
          altText
          url
          sm
          md
          lg
          xl
        }
        cta {
          url
          title
          target
        }
      }
    }
  }
`;
