import { gql } from "@apollo/client";

export default gql`
  fragment FeatureCardVariant on FeatureCard2_3Paragraph {
    id
    featureCardTitle
    singleFeatureCards {
      id
      cards {
        ... on GeneralCardParagraph {
          title
          path
          target
          teaserImage {
            altText
            url
            sm
            md
            lg
            xl
          }
          teaserDescription
          teaserEyebrow
        }
      }
      primaryCtaLabel
      secondaryCta {
        url
        title
        target
      }
    }
    colourTheme
  }
`;
