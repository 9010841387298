import { gql } from "@apollo/client";
import { CampaignHeroFragment, ShortHeroFragment, TallHeroFragment, VideoHeroFragment, } from '@components/Hero/Hero.fragment';

export default gql`
  ${ShortHeroFragment}
  ${VideoHeroFragment}
  ${TallHeroFragment}
  ${CampaignHeroFragment}

  fragment VideoListingFragment on ListingPage {
    metatags {
      metaTitle
      metaRobots
      metaImage {
        md
      }
      metaDescription
      canonical
    }
    hero {
      ...ShortHeroParagraph
      ...TallHeroParagraph
      ...VideoHeroParagraph
      ...CampaignHeroParagraph
    }
    content {
      alignment
      text {
        processed
        format
      }
    }
    searchFilters {
      name
      values
    }
    searchResults {
      total
      pageSize
      page
      totalPages
      resultList {
        id
        title
        path
        teaserImage {
          altText
          url
          sm
          md
          lg
          xl
        }
        teaserDescription
        teaserEyebrow
      }
    }
  }
`;
