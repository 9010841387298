import {gql} from "@apollo/client";

export default gql`
  fragment ImageItemFragment on ImageItemParagraph {
    id
    image {
      url
      sm
    }
  }
`;
