import {gql} from "@apollo/client";

export default gql`
  fragment GeneralListingFragment on GeneralListingParagraph {
    id
    listingTitle: title
    listingCTA: callToAction {
      url
      title
      target
    }
    cards {
      ... on GeneralCardParagraph {
        id
        title
        path
        target
        teaserEyebrow
        teaserDescription
        image: teaserImage {
          alt: altText
          url
          sm
          md
          lg
          xl
        }
      }
    }
    description {
      processed
    }
    showDescription
    showEyebrow
  }
`;
