import {gql} from "@apollo/client";

export default gql`
  fragment FourPillarsFragment on PillarParagraph {
    id
    title
    pillarCards {
      id
      title
      circleImage {
        id
        altText
        url
        sm
        md
        lg
        xl
      }
      backgroundImage {
        id
        altText
        url
        sm
        md
        lg
        xl
      }
      description
      cta
    }
  }
`;
