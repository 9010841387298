import {gql} from "@apollo/client";

export default gql`
  fragment InfographicFragment on StatisticsParagraph {
    id
    statistics {
      statistic
      description
    }
  }
`;
