import { gql } from "@apollo/client";
import { CampaignHeroFragment, ShortHeroFragment, TallHeroFragment, VideoHeroFragment } from "@components/Hero/Hero.fragment";
import paragraphFragment from "@components/slices/fragment";

export default gql`
  ${ShortHeroFragment}
  ${VideoHeroFragment}
  ${TallHeroFragment}
  ${CampaignHeroFragment}
  ${paragraphFragment}
  fragment HomepageFragment on HomepageNode {
    id
    previewEditUrl
    latestVersion
    versionStatus
    published
    hero {
      ...ShortHeroParagraph
      ...VideoHeroParagraph
      ...TallHeroParagraph
      ...CampaignHeroParagraph
    }
    metatags {
      metaTitle
      metaRobots
      metaImage {
        md
      }
      metaDescription
      canonical
    }
    breadcrumbs {
      title
      url
    }
    path
    title
    content {
      ...WYSIWYGFragment
      ...ImpactBlockFragment
      ...PromoBlockFragment
      ...AccordionFragment
      ...InfographicFragment
      ...NewsletterSignUpBlockFragment
      ...ProjectInDetailSliderFragment
      ...FeatureCardFragment
      ...CarouselFragment
      ...DownloadFragment
      ...SpeciesFragment
      ...ImagesFragment
      ...GeneralListingFragment
      ...ImageGalleryFragment
      ...InPageTabsFragment
      ...WebformFragment
      ...FourPillarsFragment
      ...TodayAtTheZooFragment
      ...InfoBlockFragment
      ...FeatureCardVariant
      ...TestimonialsFragment
      ...EventListingFragment
      ...TextImageFragment
      ...ImageComparisonFragment
      ...VideoComponentFragment
    }
  }
`;
