import {gql} from "@apollo/client";

export default gql`
  fragment ImagesFragment on ImagesParagraph {
    id
    imageLayout
    caption
    leftImage {
      copyright
      altText
      url
      sm
      md
      lg
      xl
    }
    rightImage {
      copyright
      altText
      url
      sm
      md
      lg
      xl
    }
  }
`;
