import { gql } from "@apollo/client";

export const DonateFixedFragment = gql`
  fragment DonateFixedAmountParagraph on DonateFixedAmountParagraph  {
    title
    description
    amount
    frequency
    callToAction {
      url
      title
      target
    }
  }
`

export const DonateMultipleFragment = gql`
  fragment DonateMultipleAmountsParagraph on DonateMultipleAmountsParagraph {
    title
    description
    callToAction {
      url
      title
      target
    }
    singleOptions {
      isDefault
      options {
        label
        value
        isDefault
        description
      }
      showFieldOther
    }
    monthlyOptions {
      isDefault
      options {
        label
        value
        isDefault
        description
      }
      showFieldOther
    }
  }
`
export const DonateFragment = gql`
  ${DonateFixedFragment}
  ${DonateMultipleFragment}
  fragment DonateFragment on DonateParagraph {
    content {
      ...DonateFixedAmountParagraph
      ...DonateMultipleAmountsParagraph
    }
  }
  `;
