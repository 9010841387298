import {gql} from "@apollo/client";
import CallToActionItemFragment from '../SubParagraphs/CallToActionItem/CallToActionItem.fragment';
import ImageItemFragment from '../SubParagraphs/ImageItem/ImageItem.fragment';
import QuoteItemFragment from '../SubParagraphs/QuoteItem/QuoteItem.fragment';
import VideoItemFragment from '../SubParagraphs/VideoItem/VideoItem.fragment';

export default gql`
  ${CallToActionItemFragment}
  ${ImageItemFragment}
  ${QuoteItemFragment}
  ${VideoItemFragment}
  fragment ProjectInDetailSliderFragment on ProjectInDetailSliderParagraph {
    id
    title
    items {
      ...CallToActionItemFragment
      ...ImageItemFragment
      ...QuoteItemFragment
      ...VideoItemFragment
    }
  }
`;
