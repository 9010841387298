import {gql} from "@apollo/client";

export default gql`
  fragment ImpactBlockFragment on ImpactBlockParagraph {
    id
    title
    alignment
    callToAction {
      title
      url
      target
    }
  }
`;
