import {gql} from "@apollo/client";

export default gql`
  fragment TestimonialsFragment on TestimonialsParagraph {
    id
    title
    testimonials {
      ... on TestimonialQuoteParagraph {
        id
        quote
        citation
      }
    }
  }
`;
