import { createGlobalStyle } from 'styled-components';
import { cssReset } from './reset';
import { caption, h1, h2, h3, h4, h5, linkTemplate, mediaQueries, paragraph } from '@/theme/lib';

export const GlobalStyle = createGlobalStyle`
  //CSS reset
  ${cssReset}

  //Setting rem
  html, body, #root {
    font-size: 16px;
    word-break: break-word;
    background-color:  ${(props) => props.theme.colors.background};
  }

  //Typography
  *, body {
    font-family: ${(props) => props.theme.fonts.body};
  }

  h1 {
    ${h1}
  }

  h2 {
    ${h2}
  }

  h3 {
    ${h3}
  }

  h4 {
    ${h4}
  }

  h5 {
    ${h5}
  }

  main {
    transition: 0.2s filter;
    overflow-x: hidden;
    ${mediaQueries.lg} {
      overflow-x: initial;
    }
  }

  // Blur and darken main when a modal is open
  main.blur {
    filter: blur(5px) brightness(75%);
  }

  p, ul, ol {
    ${paragraph}
    line-height: 145%;
  }

  figcaption,
  footer > small.copyright {
    ${caption}
    color: inherit;
  }

  a {
    ${linkTemplate}
    color: ${(props) => props.theme.colors.link};
    font-weight: ${(props) => props.theme.config.linkWeight};
  }

  // Visually hidden elements for screenreaders
  .visually-hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
`;
