//London and whipsnade config is a placeholder
//TODO please change them

export const config = {
  zsl: {
    linkWeight: '400',
    buttonRadius: '5px',
    headerRadius: '5px',
    componentRadius: '5px',
    carouselAnchorRadius: '5px',
    accordionRadius: '5px',
    imageBorderRadius: {
      mobile: '5px',
      desktop: '5px',
    },
    videoBorderRadius: {
      mobile: '0',
      desktop: '0',
    },
    cards: {
      generic: {
        imageBorderRadius: {
          mobile: '5px',
          desktop: '5px',
        },
      },
      event: {
        containerBorderRadius: {
          mobile: '5px',
          desktop: '5px',
        },
        imageBorderRadius: '5px',
      },
    },
    tableRadius: {
      mobile: '5px',
      desktop: '5px',
    },
    featureCardWrapperBorderRadius: '5px',
    eventListing: {
      textTransform: 'uppercase',
    },
  },
  london: {
    linkWeight: '600',
    buttonRadius: '16px',
    headerRadius: '16px',
    componentRadius: '16px',
    carouselAnchorRadius: '25px',
    accordionRadius: '12px',
    imageBorderRadius: {
      mobile: '16px',
      desktop: '60px',
    },
    videoBorderRadius: {
      mobile: '16px',
      desktop: '24px',
    },
    cards: {
      generic: {
        imageBorderRadius: {
          mobile: '25px',
          desktop: '50px',
        },
      },
      event: {
        containerBorderRadius: {
          mobile: '40px',
          desktop: '60px',
        },
        imageBorderRadius: '40px',
      },
    },
    tableRadius: {
      mobile: '20px',
      desktop: '32px',
    },
    featureCardWrapperBorderRadius: '43px',
    eventListing: {
      textTransform: 'none',
    },
  },
  whipsnade: {
    linkWeight: '600',
    buttonRadius: '16px',
    headerRadius: '16px',
    componentRadius: '16px',
    carouselAnchorRadius: '25px',
    accordionRadius: '12px',
    imageBorderRadius: {
      mobile: '16px',
      desktop: '60px',
    },
    videoBorderRadius: {
      mobile: '16px',
      desktop: '24px',
    },
    cards: {
      generic: {
        imageBorderRadius: {
          mobile: '25px',
          desktop: '50px',
        },
      },
      event: {
        containerBorderRadius: {
          mobile: '40px',
          desktop: '60px',
        },
        imageBorderRadius: '40px',
      },
    },
    tableRadius: {
      mobile: '20px',
      desktop: '32px',
    },
    featureCardWrapperBorderRadius: '43px',
    eventListing: {
      textTransform: 'none',
    },
  },
};
