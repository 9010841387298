import {gql} from "@apollo/client";

export default gql`
  fragment CallToActionItemFragment on CallToActionItemParagraph {
    id
    title
    backgroundImage {
      url
      sm
    }
    callToAction {
      title
      url
      target
    }
  }
`;
