import {gql} from "@apollo/client";

export default gql`
  fragment InPageTabsFragment on InPageTabsParagraph {
    id
    tabs {
      ... on InPageTabParagraph {
        id
        title
        content {
          value
          format
          processed
        }
        urlHash
      }
    }
  }
`;
