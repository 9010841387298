import {gql} from "@apollo/client";

export default gql`
  fragment VideoComponentFragment on VideoComponentParagraph {
    id
    alignment
    video {
      id
      url
      thumbnail
    }
    videoType
  }
`;
