import { gql } from "@apollo/client";
import AnimalPageFragment from "@cmsPages/AnimalPage/AnimalPage.fragment";
import ArticlePageFragment from "@cmsPages/ArticlePage/ArticlePage.fragment";

import AnimalListingFragment from "@cmsPages/AnimalListing/AnimalListing.fragment";
import CampaignPageFragment from "@cmsPages/CampaignPage/CampaignPage.fragment";
import CoursePageFragment from "@cmsPages/CoursePage/CoursePage.fragment";
import DonationPageFragment from "@cmsPages/DonationPage/DonationPage.fragment";
import EventPageFragment from "@cmsPages/EventPage/EventPage.fragment";
import HomepageFragment from "@cmsPages/Homepage/Homepage.fragment";
import InformationPageFragment from "@cmsPages/InformationPage/InformationPage.fragment";
import LandingPageFragment from "@cmsPages/LandingPage/LandingPage.fragment";
import NewsListingFragment from "@cmsPages/NewsListing/NewsListing.fragment";
import NewsPageFragment from "@cmsPages/NewsPage/NewsPage.fragment";
import PeopleListingFragment from "@cmsPages/PeopleListing/PeopleListing.fragment";
import PersonPageFragment from "@cmsPages/PersonPage/PersonPage.fragment";
import PillarPageFragment from "@cmsPages/PillarPage/PillarPage.fragment";
import PlacePageFragment from "@cmsPages/PlacePage/PlacePage.fragment";
import ProjectListingFragment from "@cmsPages/ProjectListing/ProjectListing.fragment";
import ProjectPageFragment from "@cmsPages/ProjectPage/ProjectPage.fragment";
import SearchPageFragment from "@cmsPages/SearchPage/Search.fragment";
import VideoListingFragment from "@cmsPages/VideoListing/VideoListing.fragment";
import VideoPageFragment from "@cmsPages/VideoPage/VideoPage.fragment";
import WhatsonListingFragment from "@cmsPages/WhatsOnListing/WhatsOnListing.fragment";
import DonatePopupFragment from "@components/DonatePopup/DonatePopup.fragment";

export const getCMSContentOrRedirect = gql`
  ${AnimalPageFragment}
  ${PillarPageFragment}
  ${ArticlePageFragment}
  ${CampaignPageFragment}
  ${DonationPageFragment}
  ${CoursePageFragment}
  ${EventPageFragment}
  ${HomepageFragment}
  ${InformationPageFragment}
  ${LandingPageFragment}
  ${NewsPageFragment}
  ${PersonPageFragment}
  ${PlacePageFragment}
  ${ProjectPageFragment}
  ${VideoPageFragment}
  query GetCMSContentOrRedirect(
    $theme: String!
    $path: String!
  ) {
    route(theme: $theme, path: $path) {
      ... on DrupalNodeRoute {
        node {
          ...AnimalPageFragment
          ...ArticlePageFragment
          ...CampaignPageFragment
          ...DonationPageFragment
          ...ArticlePageFragment
          ...PillarPageFragment
          ...CoursePageFragment
          ...EventPageFragment
          ...HomepageFragment
          ...InformationPageFragment
          ...LandingPageFragment
          ...NewsPageFragment
          ...PersonPageFragment
          ...PlacePageFragment
          ...ProjectPageFragment
          ...VideoPageFragment
        }
      }
      ... on DrupalNotFoundRoute {
        info
      }
      ... on DrupalAccessDeniedRoute {
        info
      }
      ... on DrupalRedirectRoute {
        destination
        status
      }
      ... on DrupalOfflineRoute {
        info
      }
    }
  }
`;

export const getCMSGlobals = gql`
  ${DonatePopupFragment}
  fragment DrupalGlobalsFragment on DrupalGlobals {
    metadata {
      homepageUrl
      siteName
    }
    mainNavigationLinks {
      title
      url
      target
      ctaLabel
      description
      children {
        title
        url
        target
        description
      }
    }
    mainNavigationSettings {
      shopUrl {
        url
        title
      }
      ctaUrl {
        url
        title
      }
      secondaryUrl {
        url
        title
      }
      colourTheme
    }

    footerLinks {
      title
      url
      target
      children {
        title
        url
        target
      }
    }
    footerSettings {
      social {
        type
        url
      }
      content {
        processed
      }
      newsletter {
        ... on NewsletterSignUpBlockParagraph {
          id
          title
          supportingText
          callToAction {
            title
            url
            target
          }
        }
      }
      copyright {
        description {
          processed
        }
      }
    }

    donationPopup {
      ...DonatePopup
    }
    nodeDetail {
      bundle
    }
    cspNonce
  }

  query GetCMSGlobals (
    $path: String!
  ) {
    globals(path: $path) {
      __typename
      ...DrupalGlobalsFragment
    }
  }
`;


export const getWhatsOnPage = gql`
  ${WhatsonListingFragment}
  query GetWhatsOnPage (
    $page: Int!
    $filterTags: [String]
    $eventDateFrom: String
  ) {
    whatson(page: $page, filterTags: $filterTags, eventDateFrom: $eventDateFrom) {
      ...WhatsonListingFragment
    }
  }
`;

export const getPeoplePage = gql`
  ${PeopleListingFragment}
  query GetPeoplePage (
    $page: Int!
    $filterDept: [String]
  ) {
    people(page: $page, filterDept: $filterDept) {
      ...PeopleListingFragment
    }
  }
`;

export const getNewsPage = gql`
  ${NewsListingFragment}
  query GetNewsPage (
    $type: String
    $page: Int!
    $filterDept: [String]
  ) {
    news(type: $type, page: $page, filterDept: $filterDept) {
      ...NewsListingFragment
    }
  }
`;

export const getAnimalPage = gql`
  ${AnimalListingFragment}
  query GetAnimalPage (
    $page: Int!
    $filterType: [String]
  ) {
    animals(page: $page, filterType: $filterType) {
      ...AnimalListingFragment
    }
  }
`;

export const getVideoPage = gql`
  ${VideoListingFragment}
  query GetVideoPage (
    $page: Int!
    $filterType: [String]
  ) {
    videos(page: $page, filterType: $filterType) {
      ...VideoListingFragment
    }
  }
`;

export const getProjectPage = gql`
  ${ProjectListingFragment}
  query GetProjectPage (
    $page: Int!
    $filterSpecies: [String]
    $filterSolution: [String]
    $filterDept: [String]
  ) {
    projects(page: $page, filterSpecies: $filterSpecies, filterSolution: $filterSolution, filterDept: $filterDept) {
      ...ProjectListingFragment
    }
  }
`;
export const getSearchPage = gql`
  ${SearchPageFragment}
  query GetSearchPage (
    $keywords: String!
    $sortBy: String
    $page: Int
  ) {
    search(keywords: $keywords, sortBy: $sortBy, page: $page) {
      ...SearchPageFragment
    }
  }
`;

export const getStatus = gql`
   query GetStatus($path: String!) {
     globals(path: $path) {
       __typename
     }
   }
`;
