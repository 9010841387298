import {gql} from "@apollo/client";

export default gql`
  fragment SpeciesFragment on SpeciesParagraph {
    id
    title
    cards: speciesCards {
      name
      status: iucnStatus
      url
    }
  }
`;
