import {gql} from "@apollo/client";

export default gql`
  fragment CarouselFragment on CarouselParagraph {
    id
    carouselTitle: title
    overviewLink {
      url
      title
      target
    }
    cards {
      id
      title
      path
      teaserImage {
        altText
        url
        sm
        md
        lg
        xl
      }
      teaserDescription
      teaserEyebrow
    }
    showDescription
    showEyebrow
  }
`;
