import { gql } from "@apollo/client";
import FeatureCardFragment from '@components/FeatureCard/FeatureCard.fragment';
import { CampaignHeroFragment, ShortHeroFragment, TallHeroFragment, VideoHeroFragment, } from '@components/Hero/Hero.fragment';

export default gql`
  ${ShortHeroFragment}
  ${VideoHeroFragment}
  ${TallHeroFragment}
  ${CampaignHeroFragment}
  ${FeatureCardFragment}

  fragment WhatsonListingFragment on WhatsonPage {
    metatags {
      metaTitle
      metaRobots
      metaImage {
        md
      }
      metaDescription
      canonical
    }
    hero {
      ...ShortHeroParagraph
      ...TallHeroParagraph
      ...VideoHeroParagraph
      ...CampaignHeroParagraph
    }
    featuredCard {
      ...FeatureCardFragment
    }
    content {
      alignment
      text {
        processed
        format
      }
    }
    searchFilters {
      name
      values
    }
    searchResults {
      total
      pageSize
      page
      totalPages
      resultList {
        id
        title
        path
        target
        startDate
        teaserImage {
          altText
          url
          sm
          md
          lg
          xl
        }
        teaserDescription
        teaserEyebrow
        label
      }
    }
  }
`;

export const SearchFilterFragment = gql`
  fragment SearchFilterFragment on SearchFilter {
    name
    values
  }
`;

export const WhatsonResultsFragment = gql`
  fragment WhatsOnResultsFragment on WhatsonResults {
    page
    totalPages
    resultList {
      id
      title
      path
      startDate
      teaserImage {
        altText
        url
      }
      teaserDescription
      teaserEyebrow
      label
    }
  }
`;
