import {gql} from "@apollo/client";

export default gql`
  fragment TodayAtTheZooFragment on TodayAtTheZooListingParagraph {
    id
    title
    schedule {
      ... on TodayAtTheZooCardParagraph {
        id
        title
        startDate
        path
        teaserEyebrow
        teaserDescription
        teaserImage {
          alt: altText
          url
          sm
          md
          lg
          xl
        }
      }
    }
  }
`;
