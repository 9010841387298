import {PropsWithChildren, ReactElement} from 'react';
import {ThemeProvider} from 'styled-components';
import {config} from './settings/config';
import {colors} from './settings/colors';
import {fonts} from './settings/fonts';

export const themes = {
  zsl: {
    key: 'zsl',
    name: 'ZSL',
    colors: colors.zsl,
    config: config.zsl,
    fonts: fonts.zsl,
  },
  london: {
    key: 'london',
    name: 'London Zoo',
    colors: colors.london,
    config: config.london,
    fonts: fonts.london,
  },
  whipsnade: {
    key: 'whipsnade',
    name: 'Whipsnade Zoo',
    colors: colors.whipsnade,
    config: config.whipsnade,
    fonts: fonts.whipsnade,
  },
};

type Props = PropsWithChildren<{
  theme: 'zsl' | 'london' | 'whipsnade';
}>;

const Theme = ({ theme, children }: Props): ReactElement => {
  const currentTheme = themes[theme] || themes['zsl'];

  return (
    <ThemeProvider theme={currentTheme}>
      {children}
    </ThemeProvider>
  );
};

export default Theme;
