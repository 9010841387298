import {gql} from "@apollo/client";

export default gql`
  fragment InfoBlockFragment on InfoBlockParagraph {
    infoBlockLinks {
      ... on InfoBlockLinkParagraph {
        icon
        url {
          url
          title
          target
        }
      }
    }
  }
`;
